import React from 'react';
// import ReactIframeResizer from 'react-iframe-resizer-super';
import Spinner from '../UI/Spinner/Spinner';
import * as style from './Comments.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faComment } from '@fortawesome/free-solid-svg-icons';



const Comments = (props) => {
  const iframeResizerOptions = { 
    autoResize: true,
    checkOrigin: false,
    onInit: () => {
      toggleSpinner();
    }
  };
  const [showComments, setshowComments] = React.useState(true);
  const [showSpinner, setshowSpinner] = React.useState(true);
  
  const toggleSpinner = () => {
      setshowSpinner(!showSpinner);
    return true;
  };
  const headline = props.params.commentsHeadline ? props.params.commentsHeadline : 'Teile deine Meinung zu diesem Artikel!';

  // const toggleIframe = (e) => {
  //   e.preventDefault();
  //   if(showComments && !showSpinner) {
  //      toggleSpinner();
  //   }
  //   setshowComments(!showComments);
  // }
  // debugger;
  // return <p>Comments ausgestellt wegen iframe resizer DISABLED</p>
  return (
    <div>
      <hr className={style.Line}/>
      <h1><FontAwesomeIcon className={style.CommentIcon} icon={faComment} />{headline}</h1>
      {/* <a href="#" onClick={(e)=> {toggleIframe(e)}} className={style.ShowIframeButton}> 
        <Arrow to={!showComments ? 'down' : 'up'} />
        {!showComments ? 'Zeige mir' : 'Verstecke '} die Kommentare
      </a> */}
       <p style={{fontStyle: "italic"}}>Kommentare sind zur Zeit aus technischen Gründen deaktiviert.</p>
      {false && showComments ? 
      <>
        {showSpinner ? <Spinner additionalClass={style.CenteredSpinner}/> : null }
        
        {/* <ReactIframeResizer DISABLED
          iframeResizerOptions={iframeResizerOptions}
          iframeResizerEnable={true}
          iframeResizerUrl={false}
          src={`https://tiny-house-helden.de/wp-inhalte/${props.category}/${props.articleName}?iframe=showOnlyComment`}
        > 

      </ReactIframeResizer>  */}
      </> : null}
    </div>
  );
}

export default Comments;