import React from 'react';
import parse, {domToReact} from "html-react-parser";




const books = new Map([

  ['/$afl-collins-diy', 'https://amzn.to/2WxPDaT'],
  ['/$afl-geschichten-thv', 'https://amzn.to/2CWkzdK'],
  ['/$afl-grosse-freiheit','https://amzn.to/301u84u'],
  ['/$afl-minimalismus-praxis','https://amzn.to/2CR9zPb'],
  ['/$afl-anders-wohnen','https://amzn.to/3jHYO2o'],
  ['/$afl-richtiges-aufrauemen','https://amzn.to/2GvAzpl'],
  ['/$afl-grosse-buch-selbstversorgung','https://amzn.to/30AF8Fx'],
  ['/$afl-vier-raeder-kueche-bad','https://amzn.to/3iCUEHp'],
  ['/$afl-trick17-heimwerken','https://amzn.to/36DLM1S'],
  ['/$afl-glueck-kleinste-huette','https://amzn.to/3jNJUqj'],
  ['/$afl-wecke-heimwerker','https://amzn.to/3jOFlM8'],
  ['/$afl-tiny-house-praxisbuch','https://amzn.to/3mKEPk6'],
  ['/$afl-tiny-house-fibel','https://amzn.to/3oZS1Ue'],
  ['/$afl-minimalismus-es-kann-so-leicht-sein','https://amzn.to/3k0W27s'],
  ['/$afl-unser-hof-bretagne','https://amzn.to/3518Czh'],
  ['/$afl-kaffee-kaeuzchen','https://amzn.to/36apsuP'],
  ['/$afl-tinyhouses-kleine-haeuser-grosse-traeume','https://amzn.to/2I8JLAs'],
  ['/$afl-xxxxx','xxx'],
]);

const products = new Map([

  ['/$afl-bubble-rain','https://amzn.to/3Q1CxeY'],
  ['/$afl-csl-tischventilator','https://amzn.to/33zTAjc'],
  ['/$afl-powerbank-baseus','https://amzn.to/3Q3RnRZ'],
  ['/$afl-dyson-v8','https://amzn.to/30HvHVc'],
  ['/$afl-PAKASEPT-luftmessgeraet','https://amzn.to/3RgL3b6'],
  ['/$afl-eve-room-luftmessgeraet','https://amzn.to/34zDJR2'],
  ['/$afl-netatmo-luftmessgeraet','https://amzn.to/3diQC65'],
  ['/$afl-ebay-tiny-house-suche','http://rover.ebay.com/rover/1/707-53477-19255-0/1?ff3=4&pub=5575611939&toolid=10001&campid=5338727600&customid=eBay-TH-Suche&mpre=https%3A%2F%2Fwww.ebay.de%2Fsch%2Fi.html%3F_odkw%3D%2528tiny%2Bhouse%252C%2Bbauwagen%252C%2Bcontainer%252C%2Bmobilheim%2529%26_ftrt%3D901%26_udlo%3D10.000%26_sop%3D12%26_fosrp%3D1%26_sadis%3D10%26_dmd%3D1%26_mPrRngCbx%3D1%26_osacat%3D0%26LH_SALE_CURRENCY%3D0%26_ipg%3D50%26_ftrv%3D1%26_from%3DR40%26_trksid%3Dp2045573.m570.l1313%26_nkw%3D%2528tiny%2Bhouse%252C%2Bbauwagen%252C%2Bcontainer%252C%2Bmobilheim%252C%2BZirkuswagen%2529%26_sacat%3D0'],
  ['/$afl-ebay-grundstueck-suche','https://rover.ebay.com/rover/1/707-53477-19255-0/1?mpre=https%3A%2F%2Fwww.ebay.de%2Fsch%2Fi.html%3F_from%3DR40%26_trksid%3Dp2334524.m570.l1313%26_nkw%3Dgrundst%25C3%25BCck%26_sacat%3D0%26LH_TitleDesc%3D0%26_osacat%3D0%26_odkw%3Dgrundst%25C3%25BCck%2Btiny%2Bhouse&campid=5338723238&toolid=10001&customid=stellplatz-article'],
  ['/$afl-kopfhoerer-beyerdynamic','https://amzn.to/3nlO7EB'],
  ['/$afl-waternymph-wasserhahnaufsatz','https://amzn.to/2GqjHQZ'],
  ['/$afl-signalverstaerker-anntlent','https://amzn.to/3RpylXy'],
  ['/$afl-signalverstaerker-anycall','https://amzn.to/3log4tG'],
  ['/$afl-signalverstaerker-netgear','https://amzn.to/2SzzoYj'],
  ['/$afl-signalverstaerker-diy-family-store','https://amzn.to/34AXGqu'],
  ['/$afl-pro-breeze-luftentfeuchter','https://amzn.to/3nrC0pn'],
  ['/$afl-azukin-luftentfeuchter','https://amzn.to/36I3D7F'],
  ['/$afl-trotec-luftentfeuchter','https://amzn.to/30JSU97'],
  ['/$afl-songmics-sitzhocker','https://amzn.to/36I4tkY'],
  ['/$afl-sitzhocker-stauram-amznsuche','https://amzn.to/3jC6hyF'],
  ['/$afl-koenigshaus-infrarot-heizung','https://amzn.to/30LGTQA'],
  ['/$afl-projectOne-infrarot-spiegel','https://amzn.to/2HL4OJm'],
  ['/$afl-kindle-paperwhite','https://amzn.to/3K4LHGf'],
  ['/$afl-tolino-shine','https://amzn.to/3jv41t2'],
  ['/$afl-kobo-libra','https://amzn.to/2JdCShU'],
  ['/$afl-SONGMICS-sitzbank','https://amzn.to/3jzorRF'],
  ['/$afl-enjoy-cube','https://amzn.to/3oBpap6'],
  ['/$afl-spuelbecken-organizer','https://amzn.to/3wHEhmC'],
  ['/$afl-NOPNOG-aufbewahrungsbox','https://amzn.to/3e8Q2Z1'],
  ['/$afl-Joyoldelf-aufbewahrungsbox','https://amzn.to/3eeHrUv'],
  ['/$afl-dairf-geschirtuchhalter','https://amzn.to/2TCFdF8'],
  ['/$afl-blumtal-xxl-vakuumbeutel','https://amzn.to/35JttGl'],
  ['/$afl-vakuumbeutel-amazon-suche','https://amzn.to/2G8Js83'],
  ['/$afl-schallisolierung-amznsuche','https://amzn.to/3525uD4'],
  ['/$afl-amazon-music','https://amzn.to/32EVxKv'],
  ['/$afl-xxxxx','xxx'],
]);

const links  = new Map([...books, ...products]);

export default ({node}) => {
  if(!links.has(node.attribs.href)) {
    console.error(`Affiliate Map Key ${node.attribs.href} not found in AffiliateLink Component!`);
    return <p style={ {color: 'red'} }>Map Key Not Found (see console)</p>;
  }
  const href =  links.get(node.attribs.href);
  return <a href={`${href}`} target='_blank' rel='noopener noreferrer'>
    { node.children.map((child, i) => {
      return domToReact(child.parent.children);
    }
    ) }
  </a>
  // return <a href={`${href}`} target='_blank' rel='noopener noreferrer'>{ convertNodeToElement(node.children[0])  }</a>
}