import React from 'react';
import { Link } from 'gatsby';
import Layout from '../components/Layout/Layout';
import './wpArticleTemplate.scss';
import { decodeHtml, nodeIsInternalLink, nodeIsAffiliateLink, linkChecker, createId } from '../scripts/helper';
import AToLink from '../components/AToLink/AToLink';
import parse, {domToReact} from "html-react-parser";
import Axios from 'axios';
import Button from '../components/UI/Button/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretRight, faCaretLeft, faRightToBracket } from '@fortawesome/free-solid-svg-icons';
import { faClock } from '@fortawesome/free-regular-svg-icons';
// import ScrollStorage from 'gatsby-react-router-scroll/StateStorage.js';
import featImgBg from '../images/wpArticle/featImgBg3.svg';
import { Script } from "gatsby";
import { FastAverageColor } from 'fast-average-color';
import Seo from '../components/SEO/SEO';
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import AdSense from 'react-adsense';

import Comments from '../components/Comments/Comments';
import AffiliateLink from '../components/AffiliateLink/AffiliateLink';
import AdSenseRerenderer from '../components/AdSenseRerenderer/AdSenseRerenderer';
import { nanoid } from 'nanoid';




export default class WpArticleTemplate extends React.Component {
  // scrollStore = process.env.DEVELOP === 'true' ? new ScrollStorage() : null;
  scrollStore = null;

  state = {
    nextPageTitle: this.props.pageContext.nextPageTitle,
    updatedPage: null,
    showDevInfoBox: false,
    hideMyAss: false,
    featImgDominantCol: null
  };
  hasHideMyAssContent = false;
  additionalElementButtons = null;
  enhancedElements = [];
  scriptsAdded = [];
  pageType = this.props.pageContext.pageType;
  params = this.props.pageContext.params ? this.props.pageContext.params : {} ;
  // yoast_meta_title = null;
  // yoast_meta_description = null;
  faIconNames=[]; // font awesome icons array
  
  
  handleHideMyAssClick = (e) => {
    e.preventDefault();
    this.setState(prevState => {
      return {hideMyAss: !prevState.hideMyAss}
    });
  }

  handleShowHiddenContent = (i, btn) => {
    // e.preventDefault();
    const contentClass= this.additionalElementButtons[i].dataset.showEl;
    const additionalContent = document.getElementsByClassName(contentClass)[0];

    if(additionalContent.style.display === "" || additionalContent.style.display === "none") {
      additionalContent.style.display = "block";
      btn.textContent = btn.textContent.replace("⋁ Zeige", "⋀ Verstecke");
    } else {
      additionalContent.style.display = "none";
      btn.textContent = btn.textContent.replace("⋀ Verstecke", "⋁ Zeige");
    }
  }

  setStateAsync(state) {
    return new Promise((resolve) => {
      this.setState(state, resolve)
    });
  }

  async updateArticle() {
    if(process.env.DEVELOP !== "true" ) {
      return false;
    }
    const pageId = this.props.pageContext.pageId;
    const pageUrl = `https://tiny-house-helden.de/wp-inhalte/wp-json/wp/v2/pages/${pageId}?_embed`;
    const { data:page } = await Axios.get(pageUrl);
    await this.setStateAsync({updatedPage: page});
    this.setState({showDevInfoBox:false});
    return;
  }

  initAdditionalButtons() {
    this.additionalElementButtons = document.getElementsByClassName('show-additional-content');
    const that = this;

    for(let i=0; i<this.additionalElementButtons.length; i++) {

      that.enhancedElements.push({
        element :that.additionalElementButtons[i],
        clickHandler() { that.handleShowHiddenContent(i, that.additionalElementButtons[i]) },
      });

      that.enhancedElements[i].element.addEventListener('click', that.enhancedElements[i].clickHandler);
    }
  }

  bindKeyListener() {
    if(process.env.DEVELOP !== "true" ) {
      return false;
    }

    const keycodes = (e) => {
      // console.log(e);
      if(e.key ==="r" && e.ctrlKey === true) {
        this.setState({showDevInfoBox: true});
        this.updateArticle();
      }
    }
    
    if (window.addEventListener) 
      window.addEventListener("keydown", keycodes, false);
    else if (window.attachEvent) 
      window.attachEvent("onkeydown", keycodes);
      
  }

  handleScrollPosition = () => {
    if(process.env.DEVELOP !== "true" || this.props.location.key !== 'initial') {
      return;
    }
    const offset = 0;
    this.location = window.location // window is safe here
    this.location.key = 'initial' // this is what targets the grrs 2.07 change
    
    try {
      const currentPosition = this.scrollStore.read(this.location, null)
      if (currentPosition) {
        console.log('scrolling to: ' + JSON.stringify(currentPosition))
        let [x, y] = currentPosition
        window.scrollTo(x, y + offset);
      }
    } catch (e) {
      console.log('no currentPosition yet: ' + e);
    }

  }

  linkChecker = () => {
    if(process.env.DEVELOP === "true" ) {
      linkChecker('.articlePage a');
    }
  };

  async componentDidMount() {
    
    //insert font awesome icons (placeholder set in parseHtml method)
    if(this.faIconNames.length){
      import('@fortawesome/free-solid-svg-icons')
        .then((iconObject) => {
          this.faIconNames.forEach(iconName => {
            // debugger;
            this.setState({[iconName]: <FontAwesomeIcon icon={iconObject[iconName]} />})
          });
        });
    }


    this.setState((prevState) => {
      return  { nextPageTitle: decodeHtml(prevState.nextPageTitle) };
    });
    await this.updateArticle();
    this.initAdditionalButtons();
    this.bindKeyListener();
    // if(!this.props.pageContext.page.meta_description && process.env.DEVELOP === "true") {
    //   console.warn('>>>>> No meta description!!!!');
    // }
    
    this.linkChecker();
  }

  componentWillUnmount() {
    this.enhancedElements.forEach(ee => {
      ee.element.removeEventListener('click', ee.clickHandler)
    })
  }

  parseAndReplaceHtml =  (html) => {
    let index = Array.from({length:10},() => {nanoid(5)});

    const replaceFunction = (node) => {
      // debugger;
      //Todo: in einzelne Functions umwandeln zwecks wiederverwendbarkeit

      
      if( nodeIsAffiliateLink(node) ) {
        return <AffiliateLink key={index[0]} node={node} /> 
      } 
      else if( nodeIsInternalLink (node) ) {
        // console.log('Ersetze a zu link', <AToLink key={index} node={node} />);
        return <AToLink key={index[1]} node={node} /> 
      }
     
      // Hide My Ass:  Text for develpment env
      else if(node.type === 'tag' && node.attribs.class === 'hide-my-ass') {
        this.hasHideMyAssContent = true;
        if(process.env.DEVELOP !== 'true' || 
          this.state.hideMyAss || 
          this.props.location.hash === '#hideAss'
        ) {
          return null;
        }
      }

      //YouTube Video
      else if(node.type === 'tag' && node.name === 'iframe' && node.attribs.src.indexOf('youtube')) { 
        return (
          <LazyLoadComponent
            key={index[2]}
            placeholder={null}
            beforeLoad={() =>{console.log('Componente wird geladen')}}
          >
            {/* {convertNodeToElement(node)} DISABLED*/}
            {domToReact(node.parent.children)}
          </LazyLoadComponent>
        )
      }

      // font awesome
      else if(node.type === 'tag' && node.name === 'i' &&  node.attribs.class.indexOf('fas'>=0)) {
        // return undefined;
        let iconName = node.attribs.class.replace('fas ','').replace('fa-','');
        iconName = iconName.charAt(0).toUpperCase() + iconName.slice(1); //capitalize
        iconName = 'fa' + iconName;

        this.faIconNames.push(iconName);
      
        return <span key={iconName} className="iconWrapper">{this.state[iconName]}</span>;

      }

      // image wrapped by figure and/or wp-block-image class
      else if(node.type === 'tag' && node.name === 'figure') {
        if( (node.parent && node.parent.attribs && node.parent.attribs.class.indexOf('wp-block-image') >= 0) ||
            (node.attribs && node.attribs.class && node.attribs.class.indexOf('wp-block-image') >= 0) ){
              console.log("figue image node: ", node)
          return (
              <LazyLoadComponent
                key={index[3]}
                placeholder={null}
                beforeLoad={() =>{console.log('Componente wird geladen')}}
              >
               {/* Convert Node to React Element and replace Affiliate Links */}
               {domToReact(node?.parent?.children || node.children, {replace: (node)=>{
                return nodeIsAffiliateLink(node) ? <AffiliateLink node={node} /> : undefined
               }})}
               {/* {convertNodeToElement(node, index, transform)} */}

            </LazyLoadComponent>
          )
        }
      } 
      
      // script tag
      else if( node.type === 'script' ) { 
        console.log('SCRIPT TAG: ', node);
        const attr = node.attribs;
        if(!attr.id) {
          console.error('Script has no ID!', node);
          return null;
        }
        if(this.scriptsAdded.indexOf(attr.id) >= 0) {
          console.log('skip script ', attr.id, node);
          return undefined;
        } else {
          this.scriptsAdded.push(attr.id);
        }
        console.log('replace script tag with id', attr.id)
        // debugger;
        return (
          <Script 
            strategy="post-hydrate"
            {...{...attr}}
          >
            {node.children.length ? node.children[0].data : null}
          </Script>
        )
      }
      return undefined;
    }

    return parse(html, { 
      replace: replaceFunction
    });

  }

  componentDidUpdate() {
    this.handleScrollPosition();
  }
  
  render() {
    const page = this.state.updatedPage || this.props.pageContext.page;
    // const parentSlug = this.props.pageContext.parentSlug;
    const {nextPageSlug,prevPageSlug, parentSlug, continueNote } = this.props.pageContext;
    const nextPageTitle = decodeHtml(this.props.pageContext.nextPageTitle);
    const prevPageTitle = decodeHtml(this.props.pageContext.prevPageTitle);
    const featuredImage = page._embedded['wp:featuredmedia'] && page._embedded['wp:featuredmedia'][0];
    const featImgSizes = featuredImage ? featuredImage.media_details.sizes : null;
    const featImgAlt = page._embedded['wp:featuredmedia'] && featuredImage.alt_text;
    const featImgRatio = featuredImage && (featuredImage.media_details.height/featuredImage.media_details.width);
    // debugger;

    return (
    <>
    <Layout 
      headerClass="articleHeader"
      className={`centeredPage--TextPage articlePage ${!featuredImage ? 'articlePage--noFeatImg':''}`}
      headlines={this.props.pageContext.headlines}
      pageType={this.pageType}
      params={this.params}
    > 
        
      {featuredImage &&  
        <FeaturedImageContainer 
          {...{featImgRatio, featImgSizes, featImgAlt, page }}
        />
      }
      
      <div className="scriptWrapper">
      </div>
      {this.pageType !=='frontpage' ? 
      <h1 dangerouslySetInnerHTML={{__html: page.title.rendered}} /> : null
      }
      {process.env.DEVELOP === "true" && this.state.showDevInfoBox ? 
        <div className="Dev-Info-Box">Lade Content...</div> : null 
      }
      { this.parseAndReplaceHtml(page.content.rendered) }
      {this.pageType !== 'frontpage' ? 
      <AdSenseRerenderer maxWidth={700}>
        <AdSense.Google
            key={`adSense_${createId(15)}`}
            style={ {
              display:'block', 
              // textAlign: 'center',
              // marginTop: '50px'
            } }
            layout="in-article"
            format="fluid"
            client="ca-pub-2126121658624128"
            slot="1150372559"
        />
      </AdSenseRerenderer> : null}
      {nextPageSlug ? 
      <p className='nextPageInfo'>
        <FontAwesomeIcon icon={faCaretRight} /> <strong> Nächster Artikel: </strong> 
        <Link to={ `/${parentSlug}/${nextPageSlug}/` }>{nextPageTitle}</Link>
      </p> : null
      }

      {!nextPageSlug && continueNote ? 
        <p className="continueNote">
          <b>Weiterlesen: </b>
          {this.parseAndReplaceHtml(continueNote)}
        </p>: 
        null 
      }

      {prevPageSlug ? 
      <p className='prevPageInfo'>
        <FontAwesomeIcon icon={faCaretLeft} /> Vorheriger Artikel:{" "}
        <Link to={ `/${parentSlug}/${prevPageSlug}/` }>{prevPageTitle}</Link>
      </p> : null
      }

      <p></p>
      {parentSlug ? 
      <Button color='black' type='narrow' >
        <Link to={`/${parentSlug}/`}>← Zur Artikelübersicht</Link>
      </Button> : null
      }
      {process.env.DEVELOP === 'true' && this.hasHideMyAssContent ? 
        <Button>
          <a href='#top' onClick={this.handleHideMyAssClick}>
            {this.state.hideMyAss ? 'Show' : 'Hide'} My Ass
          </a>
        </Button>
      : null}
      {this.pageType !== 'frontpage' && !this.params.noComments? 
      <>
        <Comments
          category={parentSlug}
          articleName={page.slug}
          params={this.params}
        />
       
      </>: null }
    
    </Layout>
    </>
    );
  }
}
//****************************/
//*** FeaturedImageContainer */
//****************************/

const FeaturedImageContainer =  (props) => {

  const { featImgRatio, featImgSizes, featImgAlt, page } = props;
  const fac = new FastAverageColor();
  let color = "#ffffff"
  try {
    // console.log("featImgSizes.medium.source_url", featImgSizes.medium.source_url);
    // if(featImgSizes.medium.source_url) color = fac.getColor(featImgSizes.medium.source_url);
    // lead to error => todo: try new plugin
    // debugger;
  } catch(e) {
    console.log("FastAverageColor error:", e)
  }


  const getReadingTime = () => {
    const content = page.content.rendered;
    const textArray = [];

    parse(content, {
      replace: (node) => {
        const className = node.attribs && node.attribs.class;
        if(node.type === 'tag' && className === 'hide-my-ass') {
          // if(process.env.DEVELOP === "true" ) console.log("Don't count words of: ", node);
          return null;
        }
        if(node.type === 'tag' && className && className.indexOf('additional-content')>= 0) {
          // if(process.env.DEVELOP === "true" ) console.log("Don't count words of: ", node);
          return null;
        }
        if(node.type === 'text' ) {
          textArray.push(node.data);
          // console.log(node);
        }
        return undefined;
      }
    }); // End ReactHtmlParser

    const word_count = textArray.join(' ').split(' ').length;
    const wpm = 250;
    const minutes = Math.ceil(word_count / wpm);
    const seconds = Math.floor(word_count % wpm / (wpm / 60));
    const str_minutes = (minutes === 1) ? "Minute" : "Minuten";
    const str_seconds = (seconds === 1) ? "Sekunde" : "Sekunden";
    // const takeYourTimeInfo = minutes >= 9 ? 'Nicht wenig, aber manche Themen brauchen Zeit!' : '';
    const takeYourTimeInfo = '';
    return minutes === 0 ? ` ${seconds} ${str_seconds}` : ` ${minutes} ${str_minutes} <span>${takeYourTimeInfo}</span>`;
  }

 
  return (
    <div 
        className="featuredImage-wrapper"
        style={{
           // use ratio and padding for responsive image (limit height with max ratio 0.6)
          paddingBottom: featImgRatio > 0.6 ? '60%' : `${featImgRatio* 100}%`,
          backgroundImage: `url(${featImgBg})`,
          backgroundSize: 'cover',
          backgroundColor: color.hex,
          // backgroundColor: `white`
          
        }}
      >
        <div 
          className={"XXXblurred-background"}
          style={{ backgroundImage: `url(${featImgSizes.blurry_prev.source_url})`}}
        >
        
        </div>
        <img
          className="featuredImage"
          alt={featImgAlt}
          src={[
            featImgSizes.small_480.source_url
          ]}
          srcSet={`
            ${featImgSizes.medium.source_url} 320w, 
            ${featImgSizes.small_480.source_url} 480w, 
            ${featImgSizes.medium_720 ? featImgSizes.medium_720.source_url+' 720w,' : ''} 
            ${false && featImgSizes.medium_900 ? featImgSizes.medium_900.source_url+' 900w,' : ''} 
            ${(false && (featImgSizes.large &&  featImgSizes.large.source_url)) ||  featImgSizes.full.source_url} 1024w`
          }                
        />
        <span 
          className="avg-reading-time"
          style={{
            // backgroundColor:color.hex,
            // color: color.isDark ? "#ece6d7" : "rgb(70, 70, 70)",
            backgroundColor: "rgba(0,0,0,0.5)",
            color: "rgba(250, 250, 250, 0.8)",
            fontWeight: 'bold'
            
          }}
        >
          <FontAwesomeIcon icon={faClock} /> 
          Lesezeit ca.<span dangerouslySetInnerHTML={{__html: getReadingTime()}} /> 
        </span>
      </div>
  );
}

export function Head(props) {
  // TODO: use state of main component so meta information get also updated when
  // updating the website using the key combination 
  // const page = this.state.updatedPage || this.props.pageContext.page;
  const page = props.pageContext.page;
  const featuredImage = page._embedded['wp:featuredmedia'] && page._embedded['wp:featuredmedia'][0];
  const featImgSizes = featuredImage ? featuredImage.media_details.sizes : null;
  let yoast_meta_title = null;
  let yoast_meta_description = null;

  if(page.yoast_meta[4].property.indexOf('title') >= 0 ) {
    yoast_meta_title = page.yoast_meta[4].content
  } else {
    // find title
    yoast_meta_title =  page.yoast_meta.filter(el => {
      return el.property ? (el.property.indexOf('title') >= 0) : false;
    })[0].content;
    console.error('Yoast title seems to be at another position in the array!')
  }
  // check if index 0 is really description 
  if(page.yoast_meta[0].name.indexOf('description') >= 0 ) {
    yoast_meta_description = page.yoast_meta[0].content
  } else {
    // find description
    yoast_meta_description =  page.yoast_meta.filter(el => {
      return el.property ? (el.property.indexOf('description') >= 0) : false;
    })[0].content;
    console.error('Yoast description seems to be at another position in the array!')
  }


  return (
    <Seo
      title = { yoast_meta_title}
      meta_description = {yoast_meta_description}
      meta_thumbnail = {featImgSizes && featImgSizes.small_480.source_url}
      yoast_og_meta = { page.yoast_meta.slice(2, 12) }
    />
  )
}


// Temp save: transform: (node, index) => (nodeIsInternalLink (node) ? <AToLink key={index} node={node} /> : undefined)